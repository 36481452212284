import VueRouter from 'vue-router'
import type { AisRouteState } from '~/types/instantsearch'

export const attributesForProductTile = [
  'slug',
  'sku',
  'parentSku',
  'name',
  'yotpo',
  'stock',
  'finalPrice',
  'labels',
  'colorVariations',
  'defaultImage',
  'defaultVariationImage',
  'price',
  'images',
  'erpinfo',
  'variationPrices',
  'labelTags',
  'categories',
  'marketing.productManufacturer',
  'images',
  'pixmoorUrl',
  'video',
  'reviewImages'
]
export const attributesForProductTileMini = [
  'sku',
  'slug',
  'name',
  'stock',
  'finalPrice',
  'defaultImage',
  'defaultVariationImage',
  'price',
  'erpinfo',
  'variationPrices',
  'labels',
  'categories',
  'marketing.productManufacturer'
]

export const nuxtRouter = (vueRouter: VueRouter) => {
  return {
    read (): any {
      return vueRouter.currentRoute.query
    },
    write (routeState: AisRouteState) {
      if (this.createURL(routeState) === this.createURL(this.read())) { return }
      vueRouter.push({ query: routeState })
    },
    createURL (routeState: AisRouteState) {
      return vueRouter.resolve({ query: routeState }).href
    },
    onUpdate () { },
    dispose () { }
  }
}
