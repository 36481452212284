import createPersistedState from 'vuex-persistedstate'
import { Store } from 'vuex'
import { RootState } from '~/store'

export default ({ store }: ({ store: Store<RootState> })) => {
  createPersistedState({
    key: 'user-session',
    paths: [
      'user.loggedIn',
      'user.user',
      'user.details',
      'cart.items',
      'cart.createdAt'
    ]
  })(store)
}
