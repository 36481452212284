export const ProductCampaingSegments = [
  'supertarjous',
  'superhinta',
  'aina edullinen',
  'outlet',
  'tarjous',
  'uutuus'
]

export const PriceLabels = ['supertarjous', 'aina edullinen', 'superhinta', 'outlet']
