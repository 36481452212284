import { Store } from 'vuex'
import createMultiTabState from '~/modules/vuex-multi'
import { RootState } from '~/store'

export default ({ store }: ({ store: Store<RootState> })) => {
  createMultiTabState({
    statesPaths: [
      'user.loggedIn',
      'user.user',
      'user.details',
      'cart.items',
      'cart.createdAt',
      'cart.campaign'
    ]
  })(store)
}
