import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { State } from '~/types/ui'
import { RootState } from '~/store'

export const state = () => ({
  mainMenuOpen: false,
  milieuImageShown: true,
  isManualImageSelection: false,
  screenSize: null
})

export type UiState = State

export enum UiActions {
  toggleImage = 'ui/toggleImage',
  openMainMenu = 'ui/openMainMenu',
  closeMainMenu = 'ui/closeMainMenu',
  toggleMainMenu = 'ui/toggleMainMenu',
  openSearchPanel = 'ui/openSearchPanel',
  closeSearchPanel = 'ui/closeSearchPanel',
  toggleSearchPanel = 'ui/toggleSearchPanel',
  toggleMilieuImageShown = 'ui/toggleMilieuImageShown',
  setMilieuImageShown = 'ui/setMilieuImageShown',
  setScreenSize = 'ui/setScreenSize',
}

export enum UiMutations {
  setMainMenu = 'ui/SET_MAIN_MENU',
  setSearchPanel = 'ui/SET_SEARCH_PANEL',
  setGlobalMessage = 'ui/SET_GLOBAL_MESSAGE',
  setScreenSize = 'ui/SET_SCREEN_SIZE',
}

export enum UiGetters {
  isMainMenuOpen = 'ui/isMainMenuOpen',
  isMilieuImageShown = 'ui/isMilieuImageShown',
  isManualImageSelection = 'ui/isManualImageSelection',
  getSlidesInDom = 'ui/getSlidesInDom',
}

export const actions: ActionTree<UiState, RootState> = {
  openMainMenu ({ commit }): void {
    commit('SET_MAIN_MENU', true)
  },
  setScreenSize ({ commit }, payload): void {
    commit('SET_SCREEN_SIZE', payload)
  },
  closeMainMenu ({ commit }): void {
    commit('SET_MAIN_MENU', false)
  },
  toggleMainMenu ({ commit, state }): void {
    commit('SET_MAIN_MENU', !state.mainMenuOpen)
  },
  toggleMilieuImageShown ({ commit, state }): void {
    commit('SET_MILIEU_IMAGE_SHOWN', !state.milieuImageShown)
    commit('SET_MANUAL_IMAGE_SELECTION', true)
  },
  setMilieuImageShown ({ commit }, payload: boolean): void {
    commit('SET_MILIEU_IMAGE_SHOWN', payload)
  }
}

export const mutations: MutationTree<UiState> = {
  SET_MAIN_MENU (state: State, payload: boolean) {
    state.mainMenuOpen = payload
  },
  SET_MILIEU_IMAGE_SHOWN (state: State, payload: boolean) {
    state.milieuImageShown = payload
  },
  SET_MANUAL_IMAGE_SELECTION (state: State, payload: boolean) {
    state.isManualImageSelection = payload
  },
  SET_SCREEN_SIZE (state: State, payload: number) {
    state.screenSize = payload
  }
}

export const getters: GetterTree<UiState, RootState> = {
  isMainMenuOpen: (state: State): boolean => state.mainMenuOpen,
  isMilieuImageShown: (state: State): boolean => state.milieuImageShown,
  isManualImageSelection: (state: State): boolean => state.isManualImageSelection,
  getSlidesInDom: (state: State): number => {
    let showInDom = 4
    if (state.screenSize! < 768) {
      showInDom = 2
    } else if (state.screenSize! >= 769 && state.screenSize! <= 1023) {
      showInDom = 3
    }
    return showInDom
  }
}
