// import Vue from 'vue'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { ToastActions } from '~/store/toast'
import { RootState } from '~/store/index'
import { UserGetters } from '~/store/user'
import { useProduct } from '~/composable/useProduct'
import { getVariationImage, formatItemVersion } from '~/helpers/product'
import { recalculateCustomizationPrice, getProductId } from '~/helpers/customization'
import type {
  CampaignCode,
  CartType,
  DiscountedCartItem,
  GiftCard,
  CartRule,
  CartItemValidationResult,
  PriceTier,
  CampaignCodeQuery,
  CartValidationResult
} from '~/types/cart'
import type { CartItem, CartItemPrice } from '~/types/item'
import type { ProductAlgolia, StockObject } from '~/types/product'
import type { DeliveryMethod } from '~/types/checkout'
import type { CustomizationWithIdentifier } from '~/types/customization'

const deliveryMethods: DeliveryMethod[] = require('../config/delivery-methods.json')
const getDeliveryMethodsForProduct = (product: ProductAlgolia): DeliveryMethod[] => deliveryMethods.filter((dm: DeliveryMethod) => product.shippingCosts.includes(dm.id))
const { convertCartItemsToMatomoItems } = useProduct()

const getClearState = () => ({
  items: [],
  authCartMerge: false,
  authCartItems: undefined,
  createdAt: '',
  loading: false,
  giftCard: null,
  campaign: null,
  validationResult: null,
  cartRule: null,
  itemsEdited: false,
  freeItem: null
})

export const state = () => getClearState()

export type CartState = CartType

export enum CartActions {
  addToCart = 'cart/addToCart',
  addToCartBySku = 'cart/addToCartBySku',
  removeItemFromCart = 'cart/removeItemFromCart',
  reloadCartItems = 'cart/reloadCartItems',
  fetchCartProducts = 'cart/fetchCartProducts',
  addItemsFromAUthCart = 'cart/addItemsFromAUthCart',
  addCurrentItemsToAuthCart = 'cart/addCurrentItemsToAuthCart',
  removeItemFromAuthCart = 'cart/removeItemFromAuthCart',
  setAuthCartMergeStatus = 'cart/setAuthCartMergeStatus',
  getAuthUserCart = 'cart/getAuthUserCart',
  updateItemAmount = 'cart/updateItemAmount',
  setCampaign = 'cart/setCampaign',
  setGiftCard = 'cart/setGiftCard',
  setCartLoadingState = 'cart/setCartLoadingState',
  useCampaignCode = 'cart/useCampaignCode',
  useGiftCardCode = 'cart/useGiftCardCode',
  clearCart = 'cart/clearCart',
  setValidationResult = 'cart/setValidationResult',
  validateCart = 'cart/validateCart',
  updateItemPrice = 'cart/updateItemPrice',
  fetchCartRule = 'cart/fetchCartRule',
  recalculateCartRule = 'cart/recalculateCartRule',
  updateItemStock = 'cart/updateItemStock',
  setFreeItem = 'cart/setFreeItem',
  isCartPriceRuleAvailableForSku = 'cart/isCartPriceRuleAvailableForSku'
}

export enum CartMutations {
  setAuthCartMergeStatus = 'cart/SET_AUTH_CART_MERGE_STATUS',
  validCartCreatedAt = 'cart/validCartCreatedAt',
  clearCart = 'cart/CLEAR_CART',
  setValidationResult = 'cart/SET_VALIDATION_RESULT',
  setCartRule = 'cart/SET_CART_RULE'
}

export enum CartGetters {
  cart = 'cart/items',
  getCartItemBySku = 'cart/getCartItemBySku',
  total = 'cart/total',
  totalItemAmount = 'cart/totalItemAmount',
  items = 'cart/items',
  cartAuthMerge = 'cart/authCartMerge',
  authCartItems = 'cart/authCartItems',
  loading = 'cart/loading',
  campaign = 'cart/campaign',
  giftCard = 'cart/giftCard',
  campaignDiscountAmount = 'cart/campaignDiscountAmount',
  getDiscountedItemBySku = 'cart/getDiscountedItemBySku',
  getDiscountedItemById = 'cart/getDiscountedItemById',
  validationResult = 'cart/validationResult',
  cartRule = 'cart/cartRule',
  currentCartRuleTierDiscount = 'cart/currentCartRuleTierDiscount',
  nextCartRuleTierDiscount = 'cart/nextCartRuleTierDiscount',
  discountedCartRuleItemValue = 'cart/discountedCartRuleItemValue',
  discountedCartRuleItemAmount = 'cart/discountedCartRuleItemAmount',
  hasParentSkuInCart = 'cart/hasParentSkuInCart',
  itemsEdited = 'cart/itemsEdited',
  hasNonFreePickuItems = 'cart/hasNonFreePickuItems',
  isCartRuleAppliable = 'cart/isCartRuleAppliable',
  hasCargoItemsInCart = 'cart/hasCargoItemsInCart',
  getFreeItem = 'cart/getFreeItem'
}

const isCartRuleItem = async (state: CartType, $algolia: any, sku: string): Promise<boolean> => {
  // If no cart rule is not found, product is never a cart rule item
  if (!state.cartRule) {
    return false
  }

  const filters = state.cartRule.tierPriceFilters ? state.cartRule.tierPriceFilters + ' AND' : ''
  // If product search filter is not found (discount applies to any product), all products are cart rule items
  if (!filters) {
    return true
  }
  // If cart rule is active and product search filters were found, check if this product is cart rule item
  return await $algolia().search('', { filters: `${filters} sku:'${sku}'`, attributesToRetrieve: ['sku'], attributesToHighlight: [] })
    .then(({ hits }: { hits: ProductAlgolia[] }) => hits.length > 0)
    .catch(() => false)
}

export const actions: ActionTree<CartState, RootState> = {
  /**
   * payload:
   *  product: The product being added to cart
   *  notifyUser: Show notification for user about adding a product to cart
   *  amount: the number of products to add to cart (default 1)
   *  referrer: the component/page where the adding to cart was initiated
   */

  async addToCart ({ dispatch, commit, state }, payload: { product: ProductAlgolia, notifyUser?: boolean, amount?: number, referrer?: string, pushGtm?: boolean }) {
    // update created at
    commit('SET_CREATED_AT')
    // @ts-ignore
    // const userIsLoggedIn = rootState.user.loggedIn
    const deliveryMethodsForProduct = getDeliveryMethodsForProduct(payload.product)
    const finalPrice = payload.product.finalPrice.inclTax
    const price = payload.product.price.inclTax

    const {
      convertCartItemToGtmProduct,
      getCategoryPath,
      getAvailableAmount,
      getSegmentLabels,
      getImageTypesString
    } = useProduct()
    const cartItem: CartItem = {
      id: getProductId(payload.product),
      // @ts-ignore
      version: formatItemVersion(this.$config.ITEM_VERSION_CARTITEM),
      baseType: 'cartItem',
      sku: payload.product.sku,
      title: payload.product.name.default,
      variationName: payload.product.name.variation,
      amount: getAvailableAmount(payload.product, payload.amount || 1),
      image: getVariationImage(payload.product, 'thumbnail'),
      slug: payload.product.slug,
      price: {
        original: +price,
        final: +finalPrice,
        salePercent: price !== finalPrice ? (1 - (finalPrice / price)) * 100 : 0,
        priceRuleId: payload.product.finalPrice.ruleId
      },
      shipping: deliveryMethodsForProduct,
      variationOptions: payload.product.selectableVariationAttributes,
      productLine: payload.product.productLine,
      stock: payload.product.stock,
      erpinfo: payload.product.erpinfo,
      parentSku: payload.product.parentSku, // GTM purposes
      brands: payload.product.marketing.productManufacturer, // GTM purposes
      productCategories: payload.product.categories, // GTM purposes
      categories: getCategoryPath(payload.product.categories, this).map(cat => cat.value).join('/'), // GTM purposes
      reviews: payload.product.yotpo, // GTM purposes
      productCampaignSegment: getSegmentLabels(payload.product), // GTM purposes
      productMediaType: getImageTypesString(payload.product) // GTM purposes
    }
    if (payload.product.customization) {
      cartItem.customization = { identifier: payload.product.customization.identifier, properties: payload.product.customization.properties }
      cartItem.price.original = payload.product.customization.price
      cartItem.price.final = payload.product.customization.finalPrice
    }

    cartItem.isCartRuleItem = await isCartRuleItem(state, this.$algolia, payload.product.sku)

    commit('ADD_TO_CART', cartItem)

    // Notify user by default or if requested
    if (payload.notifyUser === undefined || payload.notifyUser) {
      dispatch(ToastActions.addToast, {
        message: (cartItem.amount > 1 ? cartItem.amount + ' kpl' : 'Tuote') + ' lisätty ostoskoriin!',
        type: 'success',
        ttl: 5000,
        cta: { label: 'Siirry ostoskoriin', to: '/ostoskori' }
      }, { root: true })
    }

    // if user is logged in, add item to cart in firestore
    // if (userIsLoggedIn) {
    //   dispatch('addToAuthCart', cartItem)
    // }

    // Matomo - Cart Update
    this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

    if (payload.pushGtm === undefined || payload.pushGtm) {
      this.app.$gtm.push({ ecommerce: null, cart_contents: null })
      this.app.$gtm.push({
        event: 'addToCart',
        addToCartLocation: payload.referrer,
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [convertCartItemToGtmProduct(cartItem)]
          }
        },
        cart_contents: state.items.map(item => convertCartItemToGtmProduct(item))
      })
    }
  },
  async reloadCartItems ({ dispatch, commit, state }) {
    // Clone items
    const items = state.items.map(i => ({ ...i }))
    commit('INIT_EMPTY_CART')
    commit('ITEMS_EDITED', true)

    for (const item of items) {
      await dispatch('addToCartBySku', { sku: item.sku, customization: item.customization, amount: item.amount, pushGtm: false, notifyUser: false })
    }
  },
  async fetchCartProducts ({ state }) {
    if (state.items.length === 0) {
      return []
    }
    const cartItemSkus: string[] = state.items.map((item: CartItem) => item.sku)
    const filters = cartItemSkus.map(sku => `sku:'${sku}'`).join(' OR ')

    return await this.$algolia().search('', { filters, attributesToHighlight: [] })
      .then(({ hits }: { hits: ProductAlgolia[] }) => {
        return hits
      })
      .catch(() => [])
  },
  async addToCartBySku ({ dispatch }, payload: { sku: string, customization?: CustomizationWithIdentifier | null, amount: number, referrer?: string, notifyUser?: boolean, pushGtm?: boolean }) {
    const filters = `sku:'${payload.sku}'`
    // Get product from Algolia by SKU, no cache
    return await this.$algolia({ cache: false }).search('', { filters, attributesToHighlight: [] })
      .then(async ({ hits }: { hits: ProductAlgolia[] }) => {
        if (hits.length === 1) {
          const algoliaProduct = { ...hits[0] }
          // Recalculate custom product prices
          if (payload.customization) {
            algoliaProduct.customization = recalculateCustomizationPrice(algoliaProduct.finalPrice.inclTax, algoliaProduct.price.inclTax, payload.customization)
          }
          return await dispatch('addToCart', { product: algoliaProduct, pushGtm: payload.pushGtm || false, notifyUser: payload.notifyUser || false, amount: payload.amount })
            .then(() => true)
            .catch(() => false)
        } else {
          return false
        }
      })
      .catch(() => false)
  },
  addCurrentItemsToAuthCart ({ dispatch, state }) {
    const items = state.items
    items.forEach((item: CartItem) => {
      dispatch('addToAuthCart', item)
    })
  },
  useCampaignCode ({ commit, state, rootState }, payload: CampaignCodeQuery) {
    /**
     * Special case:
     * If campaign code is not user initiated (eg. cart rule code) and requires login,
     * check that user is logged in to apply the campaign code
    */
    // @ts-ignore
    if (!payload.isUserInitiated && state.cartRule?.isLoginRequired && !rootState.user.loggedIn) {
      // console.log('Skipping applying campaign code as it is not user initiated and requires login, but user is not currently logged in')
      return false
    }

    commit('ITEMS_EDITED', false)
    // @ts-ignore
    return this.$axios.post(this.$config.FUNCTIONS_API_URL + '/veke3000-campaign-getCampaignCodeDetailsV2', { code: payload.code, isUserInitiated: payload.isUserInitiated, cartItems: state.items })
      .then((response: any) => {
        if (response.data.valid) {
          commit('SET_CAMPAIGN', { ...response.data.code, discountedCartItems: response.data.discountedCartItems, discountSum: response.data.discountSum })
          return response
        } else {
          // Invalid code: Keep the existing user initiated campaign in store, but set the discounts to zero when code comes back invalidated
          if (state.campaign && !state.campaign.isCartRuleTierCode && !response.data.error) {
            commit('SET_CAMPAIGN', { ...state.campaign, discountedCartItems: null, discountSum: 0 })
          }
          return false
        }
      })
  },
  useGiftCardCode ({ commit }, payload: string) {
    // @ts-ignore
    return this.$axios.get(this.$config.FUNCTIONS_API_URL + '/veke3000-campaign-getGiftCardCodeDetailsV2?code=' + payload)
      .then((response: any) => {
        if (response.data.valid && response.data.openAmount > 0) {
          commit('SET_GIFT_CARD', response.data)
          return { valid: true }
        } else if (response.data.valid && response.data.openAmount === 0) {
          return { valid: false, message: 'Lahjakortti on jo käytetty.' }
        } else {
          return { valid: false, message: `Lahjakortti '${payload.toUpperCase()}' ei ole käytössä.` }
        }
      })
  },
  fetchCartRule ({ commit, state, dispatch }) {
    // @ts-ignore
    this.$axios.get(this.$config.FUNCTIONS_API_URL + '/veke3000-campaign-getCartRuleDetailsV2')
      .then((response: any) => {
        const currentCartRule = state.cartRule
        if (!response.data.error) {
          commit('SET_CART_RULE', response.data.rule)
          // Recalculate cart items' isCartRuleItem-value if cart rule changed
          dispatch('recalculateCartRule', response.data.rule?.productSearchFilters !== currentCartRule?.productSearchFilters)
        } else {
          commit('SET_CART_RULE', null)
          dispatch('recalculateCartRule', true)
        }
      }).catch((e) => {
        console.error('Cart rule fetch failed miserably...', e)
      })
  },
  async recalculateCartRule ({ state, getters, dispatch, commit }, resetItems: boolean) {
    // update cart items if required
    if (resetItems) {
      for (const item of state.items) {
        commit('UPDATE_ITEM_IS_CART_RULE_ITEM', { sku: item.sku, isCartRuleItem: await isCartRuleItem(state, this.$algolia, item.sku) })
      }
    }

    // Only update cart rule based campaign code if no user initiated campaign code is active
    if (!state.campaign || state.campaign?.isCartRuleTierCode) {
      // By default clear the code
      commit('SET_CAMPAIGN', null)
      // Get current cart rule tier
      const currentCartRuleTier = getters.currentCartRuleTierDiscount(state.cartRule?.type)
      if (currentCartRuleTier) {
        dispatch('useCampaignCode', { code: currentCartRuleTier.campaignCode, isUserInitiated: false })
      }
      // else {
      //   commit('SET_CAMPAIGN', null)
      // }
    }
  },
  clearCart ({ commit }) {
    // Used only on Kiitos-page
    // If used elsewhere, implement matomo clearCart
    commit('CLEAR_CART')
  },
  setCampaign ({ commit }, payload: CampaignCode | null) {
    commit('SET_CAMPAIGN', payload)
  },
  setGiftCard ({ commit }, payload: GiftCard | null) {
    commit('SET_GIFT_CARD', payload)
  },
  // updateCartItem ({ commit }, payload) {
  //   commit('UPDATE_CART_ITEM', payload)
  // },
  updateItemStock ({ commit }, payload: { item: CartItem, stock: StockObject}) {
    commit('UPDATE_ITEM_STOCK', payload)
  },
  addItemsFromAUthCart ({ commit, state }) {
    const items = state.authCartItems
    // @ts-ignore
    items.forEach((item: CartItem) => {
      commit('ADD_AUTH_CART_ITEM_TO_CART', item)
      commit('REMOVE_ITEM_FROM_AUTH_CART', item)
    })
  },
  async addToAuthCart ({ rootGetters }, payload: CartItem) {
    const uid = rootGetters[UserGetters.uid]
    // @ts-ignore
    const cartSnapshot = await this.$fire.firestore.collection('veke3000-carts-auth').doc(uid).collection('items').get()
    if (cartSnapshot.docs.length > 0) {
      let itemIsInCart = false
      for (const doc of cartSnapshot.docs) {
        const dbCartItem = doc.data()
        if (dbCartItem.sku === payload.sku) {
          itemIsInCart = true
          // @ts-ignore
          const itemRef = this.$fire.firestore.collection('veke3000-carts-auth').doc(uid).collection('items').doc(doc.id)
          const item = await itemRef.get()
          const it = item.data()
          // @ts-ignore
          let originalAmount = it.amount
          originalAmount++
          // @ts-ignore
          await itemRef.update({ amount: originalAmount })
        }
      }
      if (!itemIsInCart) {
        // @ts-ignore
        await this.$fire.firestore.collection('veke3000-carts-auth').doc(uid).collection('items').add(payload)
      }
    } else { // user has no cart, create it and add item to it
      // @ts-ignore
      await this.$fire.firestore.collection('veke3000-carts-auth').doc(uid).collection('items').add(payload)
    }
  },
  setAuthCartMergeStatus ({ commit }, payload: boolean) {
    commit('SET_AUTH_CART_MERGE_STATUS', payload)
  },
  removeItemFromCart ({ commit, state }, payload: CartItem) {
    commit('REMOVE_ITEM_FROM_CART', payload)
    commit('ITEMS_EDITED', true)

    // Matomo - Cart Update
    this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

    const { convertCartItemToGtmProduct } = useProduct()
    this.app.$gtm.push({ ecommerce: null, cart_contents: null })
    this.app.$gtm.push({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [convertCartItemToGtmProduct(payload)]
        }
      },
      cart_contents: state.items.map(item => convertCartItemToGtmProduct(item))
    })
  },
  async removeItemFromAuthCart ({ commit }, payload: CartItem) {
    const userId = UserGetters.uid
    const snapshot = await this.$fire.firestore.collection('veke3000-carts-auth').doc(userId).collection('items').get()
    for (const doc of snapshot.docs) {
      const authCartItem = doc.data()
      if (authCartItem.sku === payload.sku) {
        await this.$fire.firestore.collection('veke3000-carts-auth').doc(userId).collection('items').doc(doc.id).delete()
      }
    }
    commit('REMOVE_ITEM_FROM_AUTH_CART', payload)
  },
  updateItemAmount ({ commit, getters, state }, payload: any) {
    // helper for multitab state, prevent infinity loop
    commit('ITEMS_EDITED', true)
    // const userIsLoggedIn = rootGetters[UserGetters.loggedIn]
    let oldAmount = getters.getCartItemBySku(payload.item.sku, payload.item.customization).amount
    // Set old amount as 0 if it was negative
    oldAmount = oldAmount < 0 ? 0 : oldAmount
    let newAmount = payload.amount

    // If the item is discontinued (productLine === 2), new amount cannot exceed item's stock.qty
    if (payload.item.productLine === 2 && newAmount > payload.item.stock.qty) {
      newAmount = payload.item.stock.qty
    }

    const difference = Math.abs(newAmount - oldAmount)

    // Only update item if newAmount !== oldAmount
    if (difference > 0) {
      const gtmEvent = oldAmount < newAmount ? 'addToCart' : 'removeFromCart'

      commit('SET_CART_LOADING_STATE', true)
      commit('UPDATE_ITEM_AMOUNT', payload)
      // if user is logged in, add item to cart in firestore
      /*
      if (userIsLoggedIn) {
        dispatch('updateItemAmountAuthCart', payload)
      }
      */
      commit('SET_CART_LOADING_STATE', false)

      // Matomo - Cart Update
      this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

      // Push GTM dataLayer
      const { convertCartItemToGtmProduct } = useProduct()
      const itemCopy = { ...payload.item }
      delete itemCopy.amount
      itemCopy.quantity = difference
      const eventItem = convertCartItemToGtmProduct(itemCopy)
      let eventObject = null
      if (gtmEvent === 'addToCart') {
        eventObject = {
          event: 'addToCart',
          addToCartLocation: payload.referrer,
          ecommerce: {
            currencyCode: 'EUR',
            add: { products: [eventItem] }
          },
          cart_contents: state.items.map(item => convertCartItemToGtmProduct(item))
        }
      } else {
        eventObject = {
          event: 'removeFromCart',
          ecommerce: {
            currencyCode: 'EUR',
            remove: { products: [eventItem] }
          },
          cart_contents: state.items.map(item => convertCartItemToGtmProduct(item))
        }
      }
      this.app.$gtm.push({ ecommerce: null, cart_contents: null })
      this.app.$gtm.push(eventObject)
    }
  },
  async getAuthUserCart ({ commit }, uid: string) {
    let hasCart = false
    // check if user has cart
    // @ts-ignore
    const snapshot = await this.$fire.firestore.collection('veke3000-carts-auth').doc(uid).collection('items').get()
    if (snapshot.docs.length > 0) {
      const authCartItems: CartItem[] = []
      snapshot.docs.forEach((doc: any) => {
        // @ts-ignore
        const item: CartItem = doc.data()
        // add to state
        authCartItems.push(item)
        hasCart = true
      })
      // set cart items array
      commit('SET_AUTH_CART_ITEMS', authCartItems)
    }
    commit('SET_CART_LOADING_STATE', false)
    return hasCart
  },
  async updateItemAmountAuthCart ({ rootGetters }, payload: any) {
    const uid = rootGetters[UserGetters.uid]
    // check if user has cart
    // @ts-ignore
    const snapshot = await this.$fire.firestore.collection('veke3000-carts-auth').doc(uid).collection('items').get()
    if (snapshot.docs.length > 0) {
      for (const doc of snapshot.docs) {
        // @ts-ignore
        const item = doc.data()
        if (item.sku === payload.item.sku) {
          await doc.ref.update({ amount: payload.amount })
        }
      }
    }
  },
  validCartCreatedAt ({ state, commit }) {
    if (state.createdAt) {
      const createdAt = new Date(state.createdAt)
      const now = new Date()
      now.setDate(now.getDate() - 1)
      if (createdAt.getTime() < now.getTime()) {
        commit('INIT_EMPTY_CART')
      }
    }
  },
  setCartLoadingState ({ commit }, payload: boolean) {
    commit('SET_CART_LOADING_STATE', payload)
  },
  setValidationResult ({ commit }, payload: CartItemValidationResult) {
    commit('SET_VALIDATION_RESULT', payload)
  },
  validateCart ({ commit, dispatch, state }) {
    // Reset validation errors
    commit('SET_VALIDATION_RESULT', null)
    // @ts-ignore
    return this.$axios.post(this.$config.FUNCTIONS_API_URL + '/veke3000-paymentGateway-validateCartV2', { items: state.items, campaign: state.campaign })
      .then((res) => {
        const cartValidationResult: CartValidationResult = res.data
        if (cartValidationResult.success === true) {
          return true
        } else {
          console.error('Cart validation error(s) were found:', cartValidationResult)
          commit('SET_VALIDATION_RESULT', cartValidationResult)
          if (!cartValidationResult.validations.campaignCodeValidationResult?.success) {
            commit('SET_CAMPAIGN', null)
            // After clearing current invalid campaign code, check if there's an active tier price available
            dispatch('fetchCartRule')
          }

          // Matomo - Cart Update
          this.app.$matomo.trackEcommerceCartUpdate(convertCartItemsToMatomoItems(state.items))

          return false
        }
      })
      .catch((err) => {
        // @TODO: Add error handling
        // An unhandled error occured
        console.error('validateCart-call failed:', err)
        return false
      })
  },
  updateItemPrice ({ commit }, payload: { item: CartItem, price: CartItemPrice }) {
    commit('UPDATE_ITEM_PRICE', payload)
  },
  setFreeItem ({ commit }, payload: string) {
    commit('SET_FREE_ITEM', payload)
  },
  isCartPriceRuleAvailableForSku ({ state }, sku: string) {
    return isCartRuleItem(state, this.$algolia, sku)
  }
}
export const mutations: MutationTree<CartState> = {
  SET_CAMPAIGN (state: CartType, payload: CampaignCode | null) {
    state.campaign = payload
  },
  ADD_TO_CART (state: CartType, payload: CartItem) {
    const existingProduct = state.items.find(i => i.id === payload.id)
    if (existingProduct) {
      // Discontinued product amount cannot exceed products stock.qty
      if (existingProduct.productLine === 2) {
        const amount = (existingProduct.amount + payload.amount) <= payload.stock.qty ? (existingProduct.amount + payload.amount) : payload.stock.qty
        existingProduct.amount = amount
      } else {
        existingProduct.amount = existingProduct.amount + payload.amount
      }
      // Update updated stock object on the cart item
      if (JSON.stringify(existingProduct.stock) !== JSON.stringify(payload.stock)) {
        existingProduct.stock = payload.stock
      }
    } else {
      state.items.push(payload)
    }
  },
  SET_AUTH_CART_MERGE_STATUS (state: CartType, payload: boolean) {
    state.authCartMerge = payload
  },
  REMOVE_ITEM_FROM_CART (state: CartType, payload: CartItem) {
    const index = state.items.indexOf(payload)
    state.items.splice(index, 1)
  },
  REMOVE_ITEM_FROM_AUTH_CART (state: CartType, payload: CartItem) {
    // @ts-ignore
    const index = state.authCartItems.indexOf(payload)
    // @ts-ignore
    state.authCartItems.splice(index, 1)
  },
  UPDATE_ITEM_AMOUNT (_state, payload: any) {
    payload.item.amount = payload.amount
  },
  INIT_EMPTY_CART (state) {
    state.items = []
  },
  SET_AUTH_CART_ITEMS (state: CartType, payload: CartItem[]) {
    state.authCartItems = payload
  },
  ADD_AUTH_CART_ITEM_TO_CART (state: CartType, payload: CartItem) {
    state.items.push(payload)
  },
  SET_CREATED_AT (state) {
    state.createdAt = new Date()
  },
  SET_CART_LOADING_STATE (state, payload: boolean) {
    state.loading = payload
  },
  SET_GIFT_CARD (state, payload: GiftCard) {
    state.giftCard = payload
  },
  SET_CART_RULE (state, payload: CartRule) {
    state.cartRule = payload
  },
  UPDATE_ITEM_STOCK (_state, payload: { item: CartItem, stock: StockObject}) {
    payload.item.stock = payload.stock
  },
  CLEAR_CART (state) {
    Object.assign(state, getClearState())
  },
  SET_VALIDATION_RESULT (state, payload: CartValidationResult) {
    state.validationResult = payload
  },
  UPDATE_ITEM_PRICE (_state, payload: { item: CartItem, price: CartItemPrice }) {
    payload.item.price = payload.price
  },
  UPDATE_ITEM_IS_CART_RULE_ITEM (state, payload: { sku: string, isCartRuleItem: boolean}) {
    // Find all state.items-array indices for the given SKU (multiple omaKoko-products require this)
    const indices = state.items.reduce((acc: number[], item, index) => {
      if (item.sku === payload.sku) {
        acc.push(index)
      }
      return acc
    }, [])
    // Update the exact object in given index with new isCartRuleItem-prop value
    indices.forEach(i => state.items[i].isCartRuleItem = payload.isCartRuleItem)
  },
  ITEMS_EDITED (state, payload: boolean) {
    state.itemsEdited = payload
  },
  SET_FREE_ITEM (state, payload: string) {
    state.freeItem = payload
  }
}
export const getters: GetterTree<CartState, RootState> = {
  items: (state): CartItem[] => state.items,
  getCartItemBySku: state => (sku: string, customization?: CustomizationWithIdentifier): CartItem|undefined => state.items.find((item: CartItem) => item.id === (customization?.identifier || sku)),
  totalItemAmount: (state): number => state.items.reduce((total: number, item: CartItem) => total + item.amount, 0),
  auhtCartMerge: (state): boolean | undefined => state.authCartMerge,
  authCartItems: (state): CartItem[] | undefined => state.authCartItems,
  total: (state): number => state.items.reduce((total: number, item: CartItem) => total + item.price.final * item.amount, 0),
  loading: (state): boolean | undefined => state.loading,
  itemsEdited: (state): boolean => state.itemsEdited || false,
  campaign: (state): CampaignCode | null | undefined => state.campaign,
  giftCard: (state): GiftCard | null | undefined => state.giftCard,
  campaignDiscountAmount: (state): number => state.campaign?.discountSum || 0,
  getDiscountedItemBySku: state => (sku: string) : DiscountedCartItem|undefined => state.campaign?.discountedCartItems?.find(item => item.sku === sku),
  getDiscountedItemById: state => (id: string) : DiscountedCartItem|undefined => state.campaign?.discountedCartItems?.find(item => item.id === id),
  validationResult: (state): CartValidationResult | undefined => state.validationResult,
  cartRule: (state): CartRule | null => state.cartRule,
  isCartRuleAppliable: (state, _getters, rootState: any): boolean => state.cartRule?.isLoginRequired ? rootState.user.loggedIn : true,
  discountedCartRuleItemValue: (state): number => state.items.filter(item => item.isCartRuleItem).reduce((total: number, item: CartItem) => total + item.price.final * item.amount, 0),
  discountedCartRuleItemAmount: (state): number => state.items.filter(item => item.isCartRuleItem).reduce((total: number, item: CartItem) => total + item.amount, 0),
  currentCartRuleTierDiscount: (state, getters) => (type: 'cart_value' | 'item_amount'): PriceTier | undefined => {
    const comparator = type === 'cart_value' ? getters.discountedCartRuleItemValue : getters.discountedCartRuleItemAmount
    // Sort by price DESC
    return state.cartRule?.priceTiers.slice().sort((a, b) => b.from - a.from).find(o => o.from <= comparator)
  },
  nextCartRuleTierDiscount: (state, getters) => (type: 'cart_value' | 'item_amount'): PriceTier | undefined => {
    const comparator = type === 'cart_value' ? getters.discountedCartRuleItemValue : getters.discountedCartRuleItemAmount
    // Sort by price ASC
    return state.cartRule?.priceTiers.slice().sort((a, b) => a.from - b.from).find(o => o.from > comparator)
  },
  hasParentSkuInCart: state => (parentSku: string): boolean => state.items.some((item: CartItem) => item.parentSku === parentSku),
  hasCargoItemsInCart: (state): boolean => state.items.map(m => m.shipping).flat(1).some(s => s.id.toString().startsWith('SKP')),
  getFreeItem: (state): string | undefined => state.freeItem
}
